import { TagTheme } from '@/components/Tag/theme';
import { tw } from '@/utils/tw';
import BaseArticleFooterTheme from 'base/components/ArticleFooter/theme';

const Tag = tw.theme({
  extend: TagTheme,
});

const ArticleFooter = tw.theme({
  extend: BaseArticleFooterTheme,
  slots: {
    base: `mt-6 w-full gap-4`,
    tags: ``,
    line: `hidden`,
  },
});

export default Object.assign(ArticleFooter, { Tag });
